<template>
	<div class="cs-block cs-footer-menu-block">
		<div class="cs-base-block">
			<CSFooterMenu
				:logo="data.logo || ''"
				:contacts="data.contacts || ''"
				:icons="data.icons && ((Array.isArray(data.icons) && data.icons.length) || (typeof data.icons == 'object' && Object.keys(data.icons).length)) ? data.icons : {}"
				:copyright="data.copyright || ''"
				:menu="menu"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex"

import {computed} from '../libs/common-fn';

export default {
	components: {
		CSFooterMenu: () => import("../components/CSFooterMenu.vue")
	},
	data() {
		return {
			menu: []
		}
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: computed('FooterMenu'),
	methods: {
		...mapActions("menu", ["retrieve"]),
	},
	mounted() {
		this.retrieve({ id: this.data.menu, l1: 4, l2: 3, l3: 6 }).then(menu => {
			this.menu = Object.values(menu)
		})
	},
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';

.cs-block.cs-footer-menu-block {
	background-color: #00274f;

	::v-deep {
		.footer-menu {
			background-color: #00274f;
		}

        .contacts {
            color:white;

            span {
                color:white;

                * {
                    color:white;
                }
            }
        }

        .cs-fm-m1-title {
            span {
                color: #fff;
                font-size: 1.5rem;
                font-weight: 900;
            }

            a {
                color: #ddd;
                font-size: 1.5rem;
                font-weight: 900;

                &:hover {
                    color: #c0c0c0;
                }
            }
        }

        .cs-fm-m2-title {
            color: white !important;
            font-size: 1.2rem !important;
            letter-spacing: .3px;
        }

        a.cs-fm-m2-title {
            &:hover {
                color: #c0c0c0 !important;
            }
        }

        .cs-fm-m3-title {
            span {
                color: #fff;
            }

            color: white;
            a {
                color: white;
                &:hover {
                    color: #c0c0c0;
                }
            }
        }
        .copyright {
            color: #ddd;
        }
    }
}

</style>
